export default (
  {
    req,
    res,
    route,
    $loading,
    app,
    store,
    $axios,
    $cookies,
    redirect,
    env,
    $clerk,
  },
  inject
) => {
  const validateAddress = async (address) => {
    try {
      let res = await $axios.$post(`/users/address-validation`, {
        store_id: store.getters.store_id,
        address,
      });
      return res.data;
    } catch (error) {}
  };

  const userHistory = () => {
    if (process.browser) {
      let h = localStorage.get("history");
      if (!h) {
        h = {
          items: "",
          search: [],
        };
        store.commit("history", h);
        return;
      }
      store.commit("history", JSON.parse(h));
    }
  };

  const newsLetterSign = async (e, mute) => {
    try {
      let res = await $axios.$post("newsletters", {
        store_id: store.getters.store.store_id,
        email: e,
      });
      if(process.browser){
        localStorage.setItem('newsletterEmail',e)
      }
      if (res.success && !mute) {
        $clerk.hyperAlert({
          time: 5000,
          title: "Newsletter",
          icon: "success",
          text: "Ευχαριστούμε!",
        });
      }
      $nuxt.$emit("informant:newsletter", e);
      return res;
    } catch (error) {
      $clerk.hyperAlert({
        time: 5000,
        title: "Newsletter",
        icon: "success",
        text: "Έχετε ήδη κάνει εγγραφή. Ευχαριστούμε!",
      });
    }
  };

  const newsLetterRemove = async (e, mute) => {
    try {
      let res = await $axios.$delete(`newsletters/email/${e}`, {
        store_id: store.getters.store.store_id,
        email: e,
      });
      if (res.success && !mute) {
        $clerk.hyperAlert({
          time: 5000,
          title: "Newsletter",
          icon: "success",
          text: "Το email διαγράφηκε επιτυχώς!",
        });
      }
      return res;
    } catch (error) {
      $clerk.hyperAlert({
        time: 5000,
        title: "Newsletter",
        icon: "success",
        text: "Το email διαγράφηκε επιτυχώς!",
      });
    }
  };

  const saveUser = (user, invoice, alien) => {
    let temp = "";
    if (user) {
      temp = JSON.parse(JSON.stringify(user));
    } else {
      temp = JSON.parse(JSON.stringify(store.getters.user));
      if (invoice) {
        store.getters.user.invoice = JSON.parse(JSON.stringify(invoice));
      }
    }
    if (temp.password_confirm != temp.password || temp.password == "") {
      delete temp.password;
    }
    delete temp.stores;
    temp.store_id = store.getters.store.id;
    $axios
      .$put(
        "users/me",
        { ...temp, alien },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      )
      .then((res) => {
        $clerk.hyperAlert({
          time: 3000,
          title: "Αποθηκεύτηκαν οι αλλαγές",
          icon: "success",
        });

        var temp = JSON.parse(JSON.stringify(user));
        if (temp.coupon && process.browser) {
          if (!res.couponSuccess) {
            $clerk.hyperAlert({
              time: 8000,
              title: "Το κουπόνι δεν είναι έγκυρο.",
              icon: "warning",
            });
          }
          if (res.reload) {
            $clerk.hyperAlert({
              time: 8000,
              title:
                "Πραγματοποιήστε σύνδεση ξανά για να ενεργοποιηθεί το PRO περιβάλλον.",
              icon: "success",
            });
            setTimeout(() => {
              logout();
            }, 4000);
          }
        }
        store.commit("user", temp);
        $nuxt.$emit("informant:saveUser", temp);
      })
      .catch((e) => {
        $clerk.hyperAlert({
          time: 3000,
          title: "Δεν κατέστει εφικτή η αποθήκευση!",
          icon: "error",
        });
      });
  };
  const getUser = async (exclude) => {
    let user = {};
    try {
      let res = await $axios.$get(
        `users/me?store_id=${store.getters.store.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
            ...(exclude
              ? {
                  Exclude: "noLoading",
                }
              : {}),
          },
        }
      );
      if (res.pro) {
        res.isPro = res.pro[store.getters.store.id];
      }
      user = res;
    } catch (e) {
      logout();
      console.warn(e.response.status);
      if (e.response.status == 403) {
        logout();
      }
      $clerk.hyperAlert({
        time: 4000,
        icon: "info",
        title: "Η σύνδεση έληξε!",
      });
    }
    return user;
  };

  const doLogin = async (email, password, cart) => {
    if (!cart) {
      cart = store.getters.cart;
    }
    try {
      let res = await $axios.$post("users/login", {
        email: email,
        password: password,
        store_id: store.getters.store.store_id,
      });
      localStorage.setItem("token", res.token);
      store.commit("token", res.token);
      $cookies.set("token", res.token);
      store.commit("dashboard", res.dashboard);
      if ((route.query || {}).from) {
        localStorage.setItem("redirectTo", route.query.from);
      }
      if ((route.query || {}).code) {
        localStorage.setItem("redirectToCode", route.query.code);
      }
      try{
        $nuxt.$emit("informant:login",{method:'signin',_id:res._id})
      }catch(e){
      }
      
      setTimeout(() => {location.reload();},1000)
      
      return;
      var temp = await getUser();
      store.commit("user", temp);
      await getFavorites(temp);
      let user = temp;
      if (cart) {
        cart.user = {
          id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone,
          email: user.email,
        };
        if (user.addresses) {
          cart.shipping.address = user.addresses[0];
        }
        cart.shipping.address = {};
        cart.checkout = "login";
        app.$hyperCart.saveCart(cart);
      }

      location.reload();
    } catch (e) {
      console.error(e);
      if (e.response.data.message.includes("wrong password")) {
        $clerk.hyperAlert({
          time: 4000,
          icon: "warning",
          title: "Το email ή ο κωδικός πρόσβασης είναι λανθασμένα!",
        });
        return;
      } else if (e.response.data.blocked) {
        $clerk.hyperAlert({
          icon: "warning",
          title:
            "Ο λογαριασμός έχει αποκλειστεί. Επικοινωνήστε με την υποστήριξη πελατών.",
          cancelShow: false,
          confirmButtonText: "OK",
        });
        return;
      } else if (e.response.data.reset) {
        $clerk.hyperAlert({
          icon: "warning",
          title: "Ο κωδικός πρόσβασης πρέπει να ενημερωθεί!",
          html: `Πατήστε στο κουμπί "Αποστολή Email ενημέρωσης" και μεταβείτε στο email σας <span class="font-bold">(${email})</span> για να ακολουθήσετε τη διαδικασία επαναφοράς.`,
          cancelShow: false,
          confirmButtonText: "Αποστολή Email ενημέρωσης",
          callback: (v) => {
            if (v) {
              forgotPasswordSend(email);
            }
          },
        });
        return;
      }
      $clerk.hyperAlert({
        time: 4000,
        icon: "warning",
        title: "Δεν έγινε είσοδος!",
      });
    }
  };

  const validateRegister = (register) => {
    let valid = true;
    if (
      !register.email ||
      !register.first_name ||
      !register.last_name ||
      !register.phone
    ) {
      valid = false;
    }
    if (register.password != register.password_confirm || !register.password) {
      valid = false;
    }
    return valid;
  };

  const doRegister = async (register) => {
    if (!validateRegister(register)) {
      return;
    }
    try {
      let res = await $axios.$post("users/register", {
        first_name: register.first_name,
        last_name: register.last_name,
        email: register.email,
        phone: register.phone,
        password: register.password,
        store_id: store.getters.store.store_id,
        newsletter: register.newsletter,
        coupon: register.coupon,
        GDPR: register.GDPR,
      });
      $clerk.hyperAlert({
        time: 4000,
        icon: "success",
        title: "Η εγγραφή πραγματοποιήθηκε!",
      });
      try{
        $nuxt.$emit("informant:register",{method:'signup',_id:res._id})
      }catch(e){
      }
      if (!(store.getters.cart.operator || {}).id) {
        doLogin(register.email, register.password);
      } else {
        $nuxt.$emit("clearRegister");
        $nuxt.$emit("selectUser", register.email);
        store.getters.cart.checkout = "login";
      }
      register = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
        phone: "",
        coupon: "",
        GDPR: "",
      };
    } catch (e) {
      if (
        e.response.data &&
        ((e.response.data.message || "").includes("duplicate") ||
          (e.response.data.message || "").includes("email"))
      ) {
        $clerk.hyperAlert({
          time: 4000,
          icon: "warning",
          title:
            "Υπάρχει ήδη χρήστης με αυτό το email! Αν ξεχάσατε τον κωδικό σας, ακολουθήστε τη διαδικασία επαναφοράς.",
        });
        return;
      }
      $clerk.hyperAlert({
        time: 4000,
        icon: "warning",
        title: "Δεν έγινε εγγραφή!",
      });
    }
  };
  const logout = async () => {
    store.commit("token", undefined);
    $cookies.remove("token");
    store.commit("dashboard", false);
    store.commit("user", undefined);
    let cart = store.getters.cart;
    cart.giftcards = undefined;
    cart.giftcard = undefined;
    cart.checkout = "guest";
    cart.user = {};
    await app.$hyperCart.saveCart(cart);
    localStorage.removeItem("token");
    location.reload();
  };

  const forgotPasswordSend = (email) => {
    $axios
      .$post(`/users/forget`, {
        store_id: store.getters.store.store_id,
        email,
      })
      .then((res) => {
        $clerk.hyperAlert({
          html: "Στάλθηκε email ανάκτησης στη διεύθυνση που καταχωρήσατε!",
          cancelShow: false,
        });
      })
      .catch((e) => {
        console.warn(e);
        $clerk.hyperAlert({
          time: 3000,
          title: "Αποτυχία αποστολής!",
        });
      });
  };

  const getFavorites = async (user, external) => {
    if (!((user || {}).favorites || []).length) {
      return user;
    }
    try {
      let sv = ``;
      if ((store.getters.store.eshop.search || "").includes("elastic")) {
        sv = `sv/`;
      }
      let f = await $axios.$get(
        `/items/${sv}store/${store.getters.store._id}?store_id=${
          store.getters.store._id
        }&barcodes=${((user || {}).favorites || []).join(",")}&limit=50`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
      if (f.items.docs) {
        f.items.docs = await app.$hyperItem.prepareItems(f.items.docs);
      }
      user.actualFavorites = (f.items || {}).docs || [];
    } catch (error) {
      console.error(error);
    }
    if (external) {
      external = user;
      return;
    }
    store.commit("user", user);
    return user;
  };
  const prepareUserOrders = (orders) => {
    return (orders || []).map((o) => {
      o.inspect = false;
      switch (o.step) {
        case -1:
          {
            o.status = "Ακυρώθηκε";
          }
          break;
        case 0:
          {
            o.status = "Σε επεξεργασία";
          }
          break;
        case 1:
          {
            o.status = "Σε επεξεργασία";
          }
          break;
        case 2:
          {
            o.status = "Σε μεταφορά";
          }
          break;
        case 3:
          {
            o.status = "Ολοκληρώθηκε";
          }
          break;
      }
      return o;
    });
  };
  const getOrders = async (page = 1) => {
    if (!store.getters.token || !(store.getters.user || {})._id) {
      return;
    }
    try {
      if (page) {
        page = `?page=${page}`;
      }
      let res = await $axios.$get(
        `/orders/store-user/${store.getters.store.store_id}/${store.getters.user._id}${page}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
      let temp = prepareUserOrders(res.docs);
      let user = JSON.parse(JSON.stringify(store.getters.user));
      user.orders = app.$hyperUtility.sortIt(temp, "created_at", true);
      user.orderPages = res.pages;
      user.orderTotal = res.total;
      store.commit("user", user);
    } catch (e) {
      console.warn(e);
      // $clerk.hyperAlert({
      //   time: 4000,
      //   icon: "warning",
      //   title: "Δεν ήρθε το ιστορικό των παραγγελιών!",
      // });
    }
  };
  const createAvatar = async (email) => {
    try {
      let res = await $axios.$post(
        `http://${store.getters.store.eshop.url}/gravatar`,
        {
          email,
        }
      );
      return res;
    } catch (error) {}
  };
  const missingData = async()=>{
    let user = store.getters.user;
    if(user.first_name && user.last_name && user.phone){return}
    let first_name = `<div class="field labeled"> <span>Όνομα</span> <input id="popupFirstName"/> </div>`
      let last_name = `<div class="field labeled"> <span>Επίθετο</span> <input id="popupLastName"/> </div>`
      let phone = `<div class="field labeled"> <span>Τηλέφωνο</span> <input id="popupPhone"/> </div>`
      let html = `<div class="grid grid-cols-1 gap-3">
      <p class="font-bold text-sm text-[#333]">Κάποια από τα στοιχεία του λογαριασμού σας, χρειάζεται να ενημερωθούν. Προκειμένου να προβείτε σε αγορές.</p>
      ${!user.first_name?first_name:''}
      ${!user.last_name?last_name:''}
      ${!user.phone?phone:''}
      </div>`;
      let t = await $nuxt.$clerk.hyperAlert({
        title: "Ενημέρωση στοιχείων",
        html: html,
        important: true,
        confirmButtonText:"Αποθήκευση",
        cancelButtonText:"Αργότερα",
        cancelShow:true,
        iconHtml:'https://static.pharmacy295.gr/userData-1687212428578.svg'
      });
      if(t.isConfirmed){
        
        if(!user.first_name){
          user.first_name = document.getElementById("popupFirstName").value;
          if(!user.first_name){
            missingData();
            return;
          }
        }
        if(!user.last_name){
          user.last_name = document.getElementById("popupLastName").value;
          if(!user.last_name){
            missingData();
            return;
          }
        }
        if(!user.phone){
          user.phone = document.getElementById("popupPhone").value;
          if(!user.phone){
            missingData();
            return;
          }
        }
        saveUser(user);
      }
  }
  inject("hyperUser", {
    validateAddress,
    userHistory,
    newsLetterSign,
    newsLetterRemove,
    saveUser,
    getUser,
    doLogin,
    doRegister,
    logout,
    forgotPasswordSend,
    getFavorites,
    getOrders,
    validateRegister,
    prepareUserOrders,
    createAvatar,
    missingData
  });
};
