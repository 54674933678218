export default (
  {
    req,
    res,
    route,
    $loading,
    app,
    store,
    $axios,
    $cookies,
    redirect,
    env,
    $clerk,
  },
  inject
) => {
  const isEmpty = (e, item) => {
    if (!e.hideEmpty) {
      return;
    }
    if (e.key == "discount") {
      return !(item.prices.discount > 0);
    } else if (e.key == "price") {
      if (e.onDiscount) {
        return !item.prices.discount || !(item.prices.retail_price > 0);
      }
      return !(item.prices.retail_price > 0);
    } else if (e.key == "tag_key") {
      return !(
        (((item.tags || []).filter((e) => e.key == e.tag_key) || [])[0] || [])
          .length > 0
      );
    } else if (e.key == "images") {
      return false;
    } else {
      return item[e.key];
    }
  };

  const interceptor = (item,element, product) => {
    if (!product) {
      product = JSON.parse(
        JSON.stringify(app.$hyperLayout.selectProduct(item, element))
      );
    } else {
      product = JSON.parse(JSON.stringify(product));
    }
    
    try {

      if(product.scope){
        eval(product.scope);
      }
      let actualEls = [];
      let nestedRepeat = (els,nested) => {
        let nestedEls = []
        let i = 0;
        while (i < els.length) {
          let skip = false;
          if (els[i].repeat) {
            els[i].elements = [];
            if(els[i].repeat_key){
              let values = [];
              if(els[i].repeat_key.includes("tags.")){
                values = item.tags.filter(t=>t.key==els[i].repeat_key.replace("tags.","")).map(t=>t.value);
              }else{
                values = item[els[i].repeat_key];
              }
              values.forEach((m) => {
                let element = JSON.parse(JSON.stringify(els[i]));
                element.repeat = false;
                element.text = m;
                nested?nestedEls.push(element):actualEls.push(element)
              });
              skip = true;
              els[i].repeat = false;
            }else{
              els[i].media.split(",").forEach((m) => {
                let element = JSON.parse(JSON.stringify(els[i]));
                element.repeat = false;
                element.media = m;
                els[i].elements.push({ ...element });
              });
            }
          }
          if (els[i].elements) {
            els[i].elements = nestedRepeat(els[i].elements,true);
          }
          if(!skip) nested?nestedEls.push(els[i]):actualEls.push(els[i]);
          i++;
        }
        return nestedEls;
      };
      let namespace = (p) => {
        let pp = p.replace(/{.*}/, "");
        let scriptEval = ``;
        if (pp.includes("tag.")) {
          scriptEval = eval(`item['tags_${(pp + "").replace("tag.", "")}']`);
        }else {
          let path = `['${pp}']`;
          if (pp.includes(".")) {
            path = "";
            pp.split(".").forEach((ppp) => {
              path += `['${ppp}']`;
            });
          }
          scriptEval = eval(`item${path}`);
        }
        return scriptEval;
      };
      let nested = (els) => {
        els = els.map((el) => {
          Object.keys(el).forEach((k) => {
            let scriptEval;
            try {
              let strValue = JSON.stringify(el[k]);
              if (strValue.includes("%%")) {
                strValue
                  .split("%%")
                  .filter((x) => x && !x.match(/[<>="']/))
                  .forEach((p) => {
                    try {
                      scriptEval = namespace(p);
                      try {
                        ((p || "").match(/\{(.*?)\}/) || []).forEach((pp) => {
                          if (!isNaN(pp)) {
                            scriptEval = parseFloat(scriptEval).toFixed(pp);
                          } else if (pp == "round") {
                            scriptEval = Math.round(scriptEval + "");
                          } else if (pp == "ceil") {
                            scriptEval = Math.ceil(scriptEval + "");
                          } else if (pp == "floor") {
                            scriptEval = Math.floor(scriptEval + "");
                          }
                        });
                      } catch (error) {}
                      strValue = strValue.replace(`%%${p}%%`, scriptEval);
                    } catch (error) {}
                  });
                el[k] = JSON.parse(strValue.replace(/\r?\n|\r/g, "<br>"));
              }
            } catch (error) {
              // console.error(error);
            }
          });
          if (el.elements && !el.repeat) {
            el.elements = nested(el.elements);
          }
          return el;
        });
        return els;
      };
      if (Array.isArray(product.elements)) {
        nestedRepeat(product.elements);
        product.elements = actualEls;
        
        product.elements = nested(product.elements);
        
      }
    } catch (error) {}
    return product;
  };
  const stringToObjectCSS = (str)=>{
    if(!str){
        return {};
    }
    return str.split(';').reduce((acc,_)=>{
        let a = _.split(':');
        acc[a[0]]=a[1];
        return acc;
    },{})
}
  inject("hyperProduct", {
    isEmpty,
    interceptor,
    stringToObjectCSS
  });
};
